<template>

        <div class="row ">
            <br><br><br><br><br><br>
            <!-------------------------------ACERCA DE----------------------------->
            <div class="col-lg-12">
                <div class="card-header" >
                    <div class="col-md-4"  style="">
                        <div class="card-header" >
                            <p class="card-text" v-html="this.acercade_titulo"></p>
                        </div>
                    </div>
                    <!---<div class="col-md-1"  style="">
                    </div>-->
                    <div class="col-md-8"  style="">
                            <div style="">
                                <hr style="height:3px;background:#AD00E8">
                            </div>
                    </div>
                    

                </div>
            </div>
            <div class="col-lg-12">
                <div class="card-header" >
                    <!--<div class="row" > -->
                        <div class="col-md-3"  style="">
                            <div class="" >
                                <p class="card-text" v-html="this.acercade_descripcion"></p>
                            </div>
                        </div>
                        <div class="col-md-1"  style="">
                        </div>

                        <div class="col-md-7"  style="">
                            <div class="" >
                                <p class="card-text" v-html="this.acercade_contenido"></p>
                            </div>
                        </div>

                    <!--</div> -->
                </div>
            </div>
            <hr>
            <!-------------------------------BENEFICIOS-------------------------->
            <!--<div class="row ">-->
                <div class="col-lg-12">
                    <div class="card-header" >
                        <!--<div class="row" >-->
                            <div class="col-md-12"  style="">
                                <div class="article-body">
                                    <!--<div class="card-header" > -->   
                                        <p class="card-text" v-html="this.beneficios_titulo"></p>
                                        <AcercadeBeneficios/>
                                    <!--</div>-->
                                </div>
                            </div>
                        <!--</div>-->
                    </div>
                </div>


                
            <!--</div>-->
             <!--<div class="row ">-->
                 <!--<div class="col-lg-12">-->
                     <!--<div class="home container mt-5 mb-5">-->
                        <!--<AcercadeBeneficios/>-->
                     <!--</div> -->
                 <!--</div>-->
             <!--</div>  -->

            <!-------------------------------LIMITACIONES-------------------------->


            <br><br>
           
            <div class="col-lg-12" style="background-color: #542EA5;">
                <div class="card-header cuadro-degradado" style="display: flex; justify-content: center;">
                    <div class="col-md-8">
                        <div class="card-header " style="padding: 0px 40px 0px 40px;">
                            <br><br>
                            <p class="card-text text-center" style="color: white;" v-html="this.limitaciones_contenido"></p>
                            <br><br>
                        </div>
                    </div>
                </div>
                
                
                <!--------------------------LOGOS DE ORGANIZACIONES-------------------------->

                <br><br>
                <div class="card-header" style="display: flex; justify-content: center;">
                    <div class="col-md-8 ">
                        <Logos_organizaciones/>
                    </div>    
                </div>    

                <!------------------------------ENVIO DE CORREO------------------------------>

                <br><br><br><br><br>
                <div class="card-header" style="display: flex; justify-content: center; color: white;">
                    <div class="col-md-8 ">
                        <EnvioCorreo/>
                    </div>    
                </div>
                <br>   
            </div>
            
                <!------------------------------ENVIO DE CORREO------------------------------> 
        </div>

</template>

<script>
//import getCatAcercade from '@/helpers/getCatAcercade'
import getAcercadeById from '@/helpers/getAcercadeById'
import AcercadeBeneficios from '@/components/Acercade/AcercadeBeneficios'
import Logos_organizaciones from '@/components/Acercade/Logos_organizaciones'
import EnvioCorreo from '@/components/Acercade/EnvioCorreo'


export default {
    components: {
       AcercadeBeneficios,
       Logos_organizaciones,
        EnvioCorreo
    },
    data() {
        return { 
            acercade_titulo: '',
            acercade_descripcion: '',
            acercade_contenido: '',
            beneficios_titulo: '',
            limitaciones_contenido: '',
//            contenido_organizacioones: '',
        }
    },
    methods: {
        async getCat_Acercade() {
            const datos = await getAcercadeById(  localStorage.getItem("Nidioma") )
           
            this.acercade_titulo = datos.acercade_titulo;
            this.acercade_descripcion = datos.acercade_descripcion;
            this.acercade_contenido = datos.acercade_contenido;
            this.beneficios_titulo = datos.beneficios_titulo;
            this.limitaciones_contenido = datos.limitaciones_contenido;
//            this.contenido_organizacioones = datos.contenido_organizacioones;
           
            return
        },
    },
    async mounted() {
        await this.getCat_Acercade();
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
    input {
        padding: 5px 40px;
        margin: 25px 0 50px;
        text-align: justify;
    }
    .article-body {
        text-align: justify;
    }



    .cuadro-degradado {
        background-image: radial-gradient(circle at 47.87% 25.65%, #a052ec 0, #8e48e5 50%, #7b3ede 100%);
    }

</style>