<template>
    <!-- TODO: Ajustar nuevo submenú -->
    <nav class="">
        
        <!-- Idiomas -->
 
        
        <div class="content franja-idioma-config">    <!--  La classe container pone padding al Menu -->
            <div class="navbar-header" style=" height: 100%; background-color: #542EA5;">
                    <!-- <img class="" src= "@/assets/images/logo_cc_small.png" alt="Logo"  style=" height: 80px; margin-top: 10%; margin-bottom: 10%; margin-left: 20px;">> -->

                <img class="logo-config" src= "@/assets/images/logo_cc_small.png" alt="Logo">
                    
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#MenuIdiomas">
                    <span class="sr-only">Interruptor de Navegación</span>
                    <span><img :src= "this.Gbandera" alt="Idioma"></span>
                </button>
               
            </div>
           
                <div class="collapse navbar-collapse" id="MenuIdiomas" style="  background-color:#542EA5;">   <!--542EA5-->
                    <ul class="nav navbar-nav navbar-right padre-flex"  style="height: 110px;">
                        
                            <li class="nav-item">
                                <router-link class="subnav-link"  style="padding-top: 2px; height:30px; margin-right:10px; border-radius: 10px; background-color: rgba(0, 0, 0, 0.2); color: #fff;" @click="SeleccionaIdioma(1)" to="/" title="Página Principal de Cuidando el Camino"><img :src= "this.Gbandera1" alt="Idioma"> Español</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="subnav-link"  style="padding-top: 2px; height:30px; margin-right:10px; border-radius: 10px; background-color: rgba(0, 0, 0, 0.2); color: #fff;" @click="SeleccionaIdioma(2)" to="/" title="Acerca de"><img :src= "this.Gbandera2" alt="Idioma"> English</router-link>
                            </li>

                            <li class="nav-item">
                                <router-link class="subnav-link"  style="padding-top: 2px; height:30px; margin-right:10px; border-radius: 10px; background-color: rgba(0, 0, 0, 0.2); color: #fff;" @click="SeleccionaIdioma(3)" to="/" title="Mapa de Servicios de Respuesta"><img :src= "this.Gbandera3" alt="Idioma"> Français</router-link>
                            </li>
                   
                    </ul>
                    
                </div>
           
            
        </div>
    </nav>
</template>

<script>
import charsService from '@/services/CharsService'
import getCat_MenuPaginaById from '@/helpers/getCat_MenuPaginaById'
import { icons } from '@/api/baseUrl'
//import App from '@/App.vue'
//import { createApp } from 'vue'


export default {
    
    props: {
        user: Boolean
    },
    data() {
        return {
            userId: charsService.decode(localStorage.getItem('id')),
            userIdEnc: localStorage.getItem('id'),
            logged: this.user,
            Gbandera:'',
            Gbandera1: icons.value + '/bandera_mx.png',
            Gbandera2: icons.value + '/bandera_eu.png',
            Gbandera3: icons.value + '/bandera_fr.png',
        }
    },
    methods: {
        SeleccionaIdioma(idIdioma) {
            localStorage.setItem("Nidioma", idIdioma)
            this.SeleccionaBandera(idIdioma)
            location.reload();
            //this.$GlobalIdioma = idIdioma
        },
        SeleccionaBandera(idIdioma){

            if(idIdioma == 1){
                this.Gbandera = icons.value + '/bandera_mx.png'        
            }else if(idIdioma == 2){
                this.Gbandera = icons.value + '/bandera_eu.png'        
            }else if(idIdioma == 3){
                this.Gbandera = icons.value + '/bandera_fr.png'
            }
            //alert (this.Gbandera)
        },
        async getCat_MenuPagina() {
            const datos = await getCat_MenuPaginaById( localStorage.getItem("Nidioma") )
            //localStorage.setItem("Gbtn_inicio_desc", datos.btn_inicio_desc)
            return
        },    
    },
    mounted() {
        
        if ( localStorage.getItem('nombre') ) {
            this.logged = true
            this.$route.params.tUser = this.userId
        }
        this.SeleccionaBandera(localStorage.getItem("Nidioma"))
        
        //this.$GlobalIdioma= 2
    }
}
</script>

<style>





/* SECCION PARA RESPONSIVIDAD DE LA PLATAFORMA */
        @media (min-width:320px)  { 
        /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
            .franja-idioma-config {
                height: 80px; 
            }
            .navbar-idioma-margen{
                margin-top: 0px;
                margin-right: 0px;
                background-color: #542EA5;
                /*background-color: rgb(255, 255, 255, .0);  */ 
            }
            .logo-config {
                height: 75%; 
                margin-top: 2%; 
                margin-bottom: 15%; 
                margin-left: 20px;
            }

        }
        @media (min-width:480px)  { 
        /* smartphones, Android phones, landscape iPhone */ 
            .franja-idioma-config {
                height: 80px; 
            }
            .navbar-idioma-margen{
                margin-right: 20px;
                /*background-color: rgb(255, 255, 255, .0);  */ 
            }
            .logo-config {
                height: 75%; 
                margin-top: 2%; 
                margin-bottom: 15%; 
                margin-left: 20px;
            }

        }
        @media (min-width:600px)  {  /* esta medida es la que da problemas */
        /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
            .franja-idioma-config {
                height: 110px; 
            }
            .navbar-idioma-margen{
                margin-right: 20px;
                /*background-color: rgb(255, 255, 255, .0);  */ 
            }
 
            .logo-config {
                height: 80%; 
                margin-top: 2%; 
                margin-bottom: 15%; 
                margin-left: 20px;
            }
            .padre-flex{
                height: 100%;
                margin-right: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }    

        }
        @media (min-width:801px)  { 
        /* tablet, landscape iPad, lo-res laptops ands desktops */ 
            .franja-idioma-config {
                height: 110px; 
            }
            .navbar-idioma-margen{
                margin-right: 20px;
                /*background-color: rgb(255, 255, 255, .0);  */ 
            }
        
            .logo-config {
                height: 80%; 
                margin-top: 5%; 
                margin-bottom: 10%; 
                margin-left: 20px;
            }
            .padre-flex{
                height: 100%;
                margin-right: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }    
        
        }
        @media (min-width:1025px) { 
        /* big landscape tablets, laptops, and desktops */ 
            .franja-idioma-config {
                height: 110px; 
            }
            .navbar-idioma-margen{
                margin-right: 20px;
                /*background-color: rgb(255, 255, 255, .0);  */ 
            }
        
            .logo-config {
                height: 80%; 
                margin-top: 5%; 
                margin-bottom: 10%; 
                margin-left: 20px;
            }
            .padre-flex{
                height: 100%;
                margin-right: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }   

        }
        @media (min-width:1281px) { 
        /* hi-res laptops and desktops */
            .franja-idioma-config {
                height: 110px; 
            }

            .navbar-idioma-margen{
                margin-right: 20px;
                /*background-color: rgb(255, 255, 255, .0);  */ 
            }
            .logo-config {
                height: 80%; 
                margin-top: 5%;  
                margin-bottom: 10%; 
                margin-left: 20px;
            }
            .padre-flex{
               /* background: #A8C7D6;  */
                height: 100%;
                margin-right: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            } 

        }

</style>