<template>
        
            
                <div class=" col-md-3" v-for="e in datosBeneficios" :key="e.id">
                    <div class="thumbnail"  style=" box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2); borderColor:#BFBFBF; borderWidth:0px; padding: 0px 0px 0px 0px; border-radius: 0px;">
                    
                        <div class="caption" style="height: 380px; padding-left: 40px; padding-right: 40px;">
                            <h5 class="card-title text-center ">{{ e.title }}</h5>
                            <p class="card-text" v-html="e.txt"></p>                        
                        </div>
                    </div>
                </div>
          
        


</template>

<script>
import getCatAcercade from '@/helpers/getCatAcercade'

export default {
    data() {
        return { 
            datosBeneficios: [],
        }
    },
    methods: {
        async getAcercade() {
            const dataAcercade = await getCatAcercade()
            const arrShow = []
            
            if (dataAcercade[0].bt1_titulo.length > 0){
                this.cant_box += 1;
                arrShow.push(
                    {
                       
                        title: `${ dataAcercade[0].bt1_titulo }`,
                        txt: `${ dataAcercade[0].bt1_desc }`
                       
                    },           
                )
            }        
            if (dataAcercade[0].bt2_titulo.length > 0){
                this.cant_box += 1;
                arrShow.push(
                    {
                        
                        title: `${ dataAcercade[0].bt2_titulo }`,
                        txt: `${ dataAcercade[0].bt2_desc }`
                        
                    }           
                )
            }        
            if (dataAcercade[0].bt3_titulo.length > 0 ){
                this.cant_box += 1;
                arrShow.push(
                    {
                       
                        title: `${ dataAcercade[0].bt3_titulo }`,
                        txt: `${ dataAcercade[0].bt3_desc }`
                       
                    }           
                )
            }
            if (dataAcercade[0].bt4_titulo.length > 0 ){
                this.cant_box += 1;
                arrShow.push(
                    {
                       
                        title: `${ dataAcercade[0].bt4_titulo }`,
                        txt: `${ dataAcercade[0].bt4_desc }`
                       
                    }           
                )
            }        
        
            if (this.cant_box==1){
                this.col_sm='col-sm-12 col-md-12';
            }
            if (this.cant_box==2){
                this.col_sm='col-sm-6 col-md-6';
            }
            if (this.cant_box==3){
                this.col_sm='col-sm-4 col-md-4';
            }
            if (this.cant_box==4){
                this.col_sm='col-sm-3 col-md-3';
            }

            return arrShow
        }
    },
    async mounted() {
        this.datosBeneficios = await this.getAcercade()
        window.scrollTo(0, 0)
    }
}
</script>

<style>
    .card-title {
        text-transform: uppercase;
    }
    .mt-5 {
        margin-top: 30px;
    }
    .mb-5 {
        margin-bottom: 20px;
    }
     
</style>