<template>
    <div class="row">    
        <div class="col-lg-12 panel_resp">
            <div class="row"> 
                <div class="gradient"></div>
                <div class="Imagen_responsive">
                    <img :src= "this.ImagenUrl" alt="Cuidando el camino" >
                    <!--<div class="" style="background-color: rgba(0, 0, 255, 0);"><span><p>Foto: David Peinado Romero /Shutterstock</p></span></div>-->
                </div>
                <div class="carousel-caption contenido_top">
                    
                    <div class="col-md-12" style="display: flex; justify-content: center;">
                        <div class="col-md-6"  style="">
                            <br>
                        </div>
                        <div class="col-md-6 gradient-txt contenido-princ-config"  style="">
                            

                            <span><p class="h1" v-html="this.contenido_principal"></p></span>
                            <br>
                            <button class="btn" style="background-color: #AD00E8; border-radius: 20px;"    @click="ejecuta_link"> {{this.btn_conocemas_titulo}} <i class="glyphicon glyphicon-arrow-right"></i></button>
                            
                            
                        </div>
                             
                    </div>
                        
                </div>
                <div class="padre"><div class="hijo">Foto: David Peinado Romero /Shutterstock</div></div>
            </div>

            
        </div>
        
    </div>    
</template>

<script>
//import axios from 'axios'
import getCat_principalById from '@/helpers/getCat_principalById'
//import moment from 'moment'
import { ubApi } from '@/api/baseUrl'
import { url } from '@/api/baseUrl'
//import charsService from '@/services/CharsService';


//import App from '@/App.vue'
//import { createApp } from 'vue'

export default {

    data() {
        return {
            
            ubApi: ubApi.value,
            url: url.value,
            //titulo_principal: '',
            contenido_principal: '',
            btn_conocemas_titulo: '',
            btn_conocemas_url: '',
            
            ImagenUrl: '',
        }
    },
    methods: {
        async getCat_principal() {
            const datos = await getCat_principalById(  localStorage.getItem("Nidioma") )
          //  this.titulo_principal = datos.titulo_principal
            this.contenido_principal = datos.contenido_principal
            this.btn_conocemas_titulo = datos.btn_conocemas_titulo
            this.btn_conocemas_url = datos.btn_conocemas_url
            this.ImagenUrl = this.url + '/uploads/' + datos.imagen_url
            return
        },
        async ejecuta_link() {
            this.$router.push(this.btn_conocemas_url)
        },


    },  
    async mounted() {
        await this.getCat_principal();
    }
}
</script>

<style scoped>
/*   Imagen responsiva */
/*   seccion de sombreado de la Imagen */

    

    .panel_resp{
        margin-top: 0px;
    }

    .gradient {
        background: rgb(255,255,255);
/*        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,.50) 30%, rgba(255,255,255,.50) 100%, rgba(255,255,255,0) 100%);  */
        background: linear-gradient(180deg, rgba(255,255,255,0) 100%, rgba(0,0,0,.50) 100%, rgba(255,255,255,.50) 100%, rgba(255,255,255,0) 100%);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;
        bottom: 0;
    }
    .gradient-txt1 {        /* revisar este css, se esta usando*/
        background: rgb(14, 13, 13);
/*        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,.50) 30%, rgba(255,255,255,.50) 100%, rgba(255,255,255,0) 100%);  */
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(114, 56, 231, .10) 100%, rgba(170, 136, 238, 0) 0%, rgba(108, 78, 138, 0) 0%);
        
        width: 50%;
        height: 350px;
        z-index: 100;
        /*bottom: 0;*/
    }

    .carousel-caption, .carousel-control {
       /* color: #ffffff; */
        text-shadow: 0px 0px 2px rgb(0, 0, 0);
        vertical-align:text-top;
        z-index: 100;
        width: 100%;
        /*height: 100%;*/
        padding: 0px 0px 0px 0px;
    }
    
    .btn:hover {
        color: #b0b0b0;
    }
/* APARTADO RESPONSIVO */
          @media (min-width:320px)  { 
          /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
                .contenido-princ-config{
                    margin-left: 30%;
                    margin-right: 1%;
                }
                .h1,
                    h1 {
                        font-size: 16px;
                        font-weight: normal;
                        color: #fff;
                }         
                
                .contenido_top{     /*Mueve el texto hacia arriba */
                    top: 60px;
                    left: 0px;
                }
                .Imagen_responsive {
                    margin-top: 20px;
                    width: 100%;
                    height: 400px;
                    object-fit: cover; /** fill  cover  contain  scale-down  none   */
                }
                img {
                    width: 100%;
                    height: 400px;
                }
                .padre {
                    position: relative;
                }
                .hijo {
                    color: rgb(222, 219, 219);
                    position: absolute;
                    text-align: end;
                    top: 100%;
                    width: 100%;
                    margin: -5% 0 0 -5%;
                    font-size: 12px;
                }
                


           }
          @media (min-width:480px)  { 
          /* smartphones, Android phones, landscape iPhone */ 
                .contenido-princ-config{
                    margin-left: 30%;
                    margin-right: 1%;
                }
                .h1,
                    h1 {
                        font-size: 18px;
                        font-weight: normal;
                        color: #fff;
                }         
                
                .contenido_top{     /*Mueve el texto hacia arriba */
                    top: 60px;
                    left: 0px;
                }
                .Imagen_responsive {
                    margin-top: 20px;
                    width: 100%;
                    height: 400px;
                    object-fit: cover;
                }
                img {
                    width: 100%;
                    height: 400px;
                }
                .padre {
                    position: relative;
                }
                .hijo {
                    color: rgb(222, 219, 219);
                    position: absolute;
                    text-align: end;
                    top: 100%;
                    width: 100%;
                    margin: -5% 0 0 -5%;
                    font-size: 12px;
                }
          

          }
          @media (min-width:600px)  { 
          /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
                .contenido-princ-config{
                    margin-left: 40%;
                    margin-right: 2%;
                }
                .h1,
                    h1 {
                        font-size: 22px;
                        font-weight: lighter;
                        color: #fff;
                    }
                .contenido_top{     /*Mueve el texto hacia arriba */
                    top: 150px;
                    left: 0px;
                }
                .Imagen_responsive {
                    margin-top: 50px;
                    width: 100%;
                    height: 550px;
                }
                img {
                    height: 550px;
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    width: 100%;
                }
                .padre {
                    position: relative;
                }
                .hijo {
                    color: rgb(222, 219, 219);
                    position: absolute;
                    text-align: end;
                    top: 100%;
                    width: 100%;
                    margin: -3% 0 0 -5%;
                    font-size: 12px;
                }
          }
          @media (min-width:801px)  { 
          /* tablet, landscape iPad, lo-res laptops ands desktops */ 
                .contenido-princ-config{
                    margin-left: 40%;
                    margin-right: 2%;
                }
                .h1,
                    h1 {
                        font-size: 22px;
                        font-weight: lighter;
                        color: #fff;
                    }
                .contenido_top{     /*Mueve el texto hacia arriba */
                    top: 200px;
                    left: 0px;
                }
                .Imagen_responsive {
                    margin-top: 50px;
                    width: 100%;
                    height: 600px;
                }
                img {
                    height: 600px;
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    width: 100%;
                }
                .padre {
                    position: relative;
                }
                .hijo {
                    color: rgb(222, 219, 219);
                    position: absolute;
                    text-align: end;
                    top: 100%;
                    width: 100%;
                    margin: -3% 0 0 -5%;
                    font-size: 13px;
                }
                
          }
          @media (min-width:1025px) { 
          /* big landscape tablets, laptops, and desktops */ 
                .contenido-princ-config{
                    margin-left: 0%;
                    margin-right: 2%;
                }
                .h1,
                    h1 {
                        font-size: 24px;
                        font-weight: lighter;
                        color: #fff;
                    }
                .contenido_top{     /*Mueve el texto hacia arriba */
                    top: 200px;
                    left: 0px;
                }
                .Imagen_responsive {
                    margin-top: 50px;
                    width: 100%;
                    height: 700px;
                }
                img {
                    height: 700px;
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    width: 100%;
                }
                .padre {
                    position: relative;
                }
                .hijo {
                    color: rgb(222, 219, 219);
                    position: absolute;
                    text-align: end;
                    top: 100%;
                    width: 100%;
                    margin: -3% 0 0 -5%;
                    font-size: 14px;
                }


          }
          @media (min-width:1281px) { 
          /* hi-res laptops and desktops */
                .contenido-princ-config{
                    margin-left: 0%;
                    margin-right: 2%;
                }
                .h1,
                    h1 {
                        font-size: 28px;
                        font-weight: lighter;
                        color: #fff;
                    }
                .contenido_top{     /*Mueve el texto hacia arriba */
                    top: 250px;
                    left: 0px;
                }
                .Imagen_responsive {
                    margin-top: 50px;
                    width: 100%;
                    height: 900px;
                }
                img {
                    height: 900px;
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    width: 100%;
                    filter: brightness(110%);
                   /* filter: blur(200%);*/
                    
                }
                .padre {
                    position: relative;
                }
                .hijo {
                    color: rgb(255, 255, 255);
                    position: absolute;
                    text-align: end;
                    top: 100%;
                    width: 100%;
                    margin: -3% 0 0 -5%;
                    font-size: 14px;
                }
          }

</style>